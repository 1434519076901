import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {LocationDto} from "../models/location-dto";
import {Tuple} from "../models/_generic/tuple";
import {SignalRResponse} from "../_modules/signalRTracker/x-models/signal-r-response";
import {DeviceResponseLinker} from "../_modules/signalRTracker/x-models/device-response-linker";
import {ServerEndpoints} from "../server.endpoints";
import {SalaryModifier} from "../_modules/location/_models/salary-modifier";
import {LocationNl} from "../models/location-nl";

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {
  private _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  get(id: string): Observable<LocationDto> {
    return this.http.get<LocationDto>(this._baseUrl + ServerEndpoints.Inventory_Location_Get + `?id=${id}`);
  }

  list(configuration: string = "", all: boolean = false): Observable<LocationDto[]> {
    return this.http.get<LocationDto[]>(this._baseUrl + ServerEndpoints.Inventory_Location_List + "?configuration=" + configuration + '&all=' + all);
  }

  add(name: string): Observable<LocationDto> {
    return this.http.post<any>(this._baseUrl + ServerEndpoints.Inventory_Location_Add, {name: name});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Location_Delete + "?id=" + id);
  }

  setConfig(location: string, configuration: string): Observable<Tuple<LocationDto, DeviceResponseLinker[]>> {
    return this.http.put<Tuple<LocationDto, DeviceResponseLinker[]>>(this._baseUrl + ServerEndpoints.Inventory_Location_SetLocationConfig + "?location=" + location + "&configuration=" + (configuration == "none" ? "" : configuration), {});
  }

  pushLocation(location: string): Observable<DeviceResponseLinker[]> {
    return this.http.get<DeviceResponseLinker[]>(this._baseUrl + ServerEndpoints.Inventory_Location_PushLocation + "?location=" + location);
  }

  changeName(id: string, name: string): Observable<any> {
    return this.http.put(this._baseUrl + ServerEndpoints.Inventory_Location_ChangeName,
      {
        Id: id,
        Name: name
      });
  }

  getCompanyLocations(): Observable<{
    [key: string]: LocationNl[]
  }> {
    return this.http.get<{
      [key: string]: LocationNl[]
    }>(`${this._baseUrl}${ServerEndpoints.Inventory_Location_GetCompanyLocations}`);
    // return this.http.get<any>(this._baseUrl + ServerEndpoints.Inventory_Location_GetCompanyLocations + "?start=" + (start == undefined ? "" : start.toISOString()) + "&end=" + (end == undefined ? "" : end.toISOString()));
  }

  setTipShare(id: string, receivesTip: boolean): Observable<LocationDto> {
    return this.http.put<LocationDto>(this._baseUrl + ServerEndpoints.Inventory_Location_SetTipShare + "?id=" + id + "&receivesTip=" + receivesTip, {});
  }

  setOrder(id: string, order: number): Observable<LocationDto> {
    return this.http.put<LocationDto>(this._baseUrl + ServerEndpoints.Inventory_Location_SetOrder + "?id=" + id + "&order=" + order, {});
  }

  setDefaultEmployeeCount(id: string, count: number): Observable<LocationDto> {
    return this.http.put<LocationDto>(this._baseUrl + ServerEndpoints.Inventory_Location_SetDefaultEmployeeCount + "?id=" + id + "&count=" + count, {});
  }

  setSalaryModifier(id: string, salaryModifier: SalaryModifier): Observable<LocationDto> {
    return this.http.post<LocationDto>(`${this._baseUrl}${ServerEndpoints.Inventory_Location_SetSalaryModifier}/${id}`, salaryModifier);
  }

  setValue(id: string, property: string, value: any): Observable<LocationDto> {
    return this.http.post<LocationDto>(`${this._baseUrl}${ServerEndpoints.Inventory_Location_SetValue}?id=${id}`, {
      name: property,
      value: value
    })
  }
}
