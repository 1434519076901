import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {PermissionSet} from "../../_auth/Models/PermissionSet";
import {PermissionService} from "../../_auth/permission.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";

@Component({
  selector: 'layout-form-container',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './form-container.component.html',
  styleUrl: './form-container.component.scss'
})
export class FormContainerComponent implements OnChanges, OnInit {
  constructor(private ps: PermissionService,
              private gas: GlobalAlertService) {
  }

  ngOnInit(): void {
    this.load.emit();
  }

  @ViewChild("search") search: ElementRef | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.element !== undefined && changes.element) {
      this.element.addEventListener("keyup", (e) => {
        if (this.isCollapsed || this.element === undefined) return;
        this.onSearchChange(this.element.value);
      })

      this.element.addEventListener("keydown", (e) => {
        e.key === "Escape" && this.setCollapsed(true);
        setTimeout(() => {
          this.search?.nativeElement.focus();
        })
      })
    }
  }

  @Input() hasSubmit: boolean = true;

  @Output() submit = new EventEmitter();
  @Input() submitText: string = "";
  @Output() clear = new EventEmitter();
  @Input() clearText: string = "";

  protected onSubmit(e: UIEvent) {
    e.preventDefault();
    if (!this.hasPermission()) return;

    if (!this.isCollapsed) {
      if (this.element !== undefined && this.element.value == '') {
        this.gas.createAndShow("Fehler", "Feld darf nicht leer sein.", AlertLevel.error);
        return;
      }
      this.submit.emit();
    } else {
      this.setCollapsed(false)
    }
  }

  @Input() preventClearOnCollapseChange: boolean = false;

  protected setCollapsed(value: boolean) {
    if (!value) {
      if (this.element !== undefined) {
        this.element.value = this.searchValue;
        this.element.dispatchEvent(new Event('input'));
      }
    }

    if ((this.element === undefined && !this.preventClearOnCollapseChange) || value) {
      this.onSearchChange("");
    }

    this.isCollapsed = value;
    this.isCollapsedChange.emit(this.isCollapsed);

    if (!this.isCollapsed) {
      this.load.emit();
    }

    setTimeout(() => {
      this.element?.focus();
    })
  }

  @Input() isCollapsed: boolean = true;
  @Output() isCollapsedChange = new EventEmitter<boolean>();

  protected collapse(e: UIEvent) {
    e.preventDefault();
    this.setCollapsed(true);
  }

  @Input() hasSearch: boolean = true;
  @Input() searchValue: string = "";
  @Output() searchValueChange = new EventEmitter<string>();

  protected onSearchChange(value: string) {
    this.searchValue = value;
    this.searchValueChange.emit(this.searchValue);
  }

  @Input() permission: PermissionSet | undefined;

  protected hasPermission(): boolean {
    if (!this.hasSubmit) return false;

    if (!this.permission) return true;
    return this.ps.CheckPermission(this.permission);
  }

  @Input() element: HTMLInputElement | undefined;

  protected preventDefault($event: Event) {
    $event.preventDefault();
  }

  @Output() load = new EventEmitter();
}
