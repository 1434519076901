import {EventEdto} from "../../../models/event/event-edto";
import {BookingType, BookingTypeLabels} from "../../../models/payment/booking-type";
import {OrderHelper} from "./order-helper";

export class EventHelper {
  public static getTotalNumeric(values: { [key: number]: { count: number, amount: number } } | {
    [key: number]: { amount: number }
  }): number {
    return Object.keys(values).reduce((acc, value) => {
      acc += values[parseInt(value)].amount;
      return acc;
    }, 0);
  }

  public static getTotalString(values: { [key: string]: { name: string, count: number, amount: number } }): number {
    return Object.keys(values).reduce((acc, value) => {
      acc += values[value].amount;
      return acc;
    }, 0);
  }

  public static getCountNumeric(values: { [key: number]: { count: number, amount: number } }): number {
    return Object.keys(values).reduce((acc, value) => {
      acc += values[parseInt(value)].count;
      return acc;
    }, 0);
  }

  public static getCountString(values: { [key: string]: { name: string, count: number, amount: number } }): number {
    return Object.keys(values).reduce((acc, value) => {
      acc += values[value].count;
      return acc;
    }, 0);
  }

  public static setEventBookingValues(event: EventEdto) {
    let result: { [key: number]: { count: number, amount: number } } = {};
    event.orders.reduce((acc, value) => {
      Object.keys(value.bookingValues).forEach(key => {
        let k = parseInt(key);
        if (k == BookingType.Employee) return;

        if (acc[k] == undefined) acc[k] = {count: 0, amount: 0};
        acc[k].count += value.bookingValues[k].count;
        acc[k].amount += value.bookingValues[k].amount;
      })
      return acc;
    }, result);
    event.bookingValues = result;
  }

  public static setOtherCompositionValues(event: EventEdto) {
    let result: { [key: string]: { name: string, count: number, amount: number } } = {};
    event.orders
      .filter(order => order.bookingType != BookingType.Employee)
      .reduce((acc, value) => {
        value.compositions.forEach(composition => {
          if (composition.composition.tags.findIndex(x => x.other) == -1) return;
          if (acc[composition.composition.id] == undefined) acc[composition.composition.id] = {
            name: composition.composition.name,
            count: 0,
            amount: 0
          };
          acc[composition.composition.id].count += composition.quantity;
          acc[composition.composition.id].amount += composition.quantity * (composition.composition.oPrice ?? composition.composition.price);
        })
        return acc;
      }, result);
    event.otherCompositionValues = result;
  }

  public static getCompositionValuePairs(values: { [p: string]: { name: string, count: number, amount: number } }): {
    name: string,
    count: number,
    amount: number
  }[] {
    return Object.keys(values).map(key => {
      return values[key];
    }).sort((a, b) => b.count - a.count);
  }

  public static getBookingValuePairs(values: { [key: number]: { count: number, amount: number } }): {
    type: string,
    count: number,
    amount: number
  }[] {
    return Object.keys(values).map(key => {
      let k = parseInt(key);
      return {type: BookingTypeLabels[k], count: values[k].count, amount: values[k].amount};
    }).sort((a, b) => b.amount - a.amount);
  }

  public static setNormalCompositionValues(event: EventEdto) {
    let result: { [key: string]: { name: string, count: number, amount: number } } = {};
    event.orders
      .filter(order => order.bookingType != BookingType.Employee)
      .reduce((acc, value) => {
        value.compositions.forEach(composition => {
          if (composition.composition.tags.findIndex(x => x.other) != -1) return;
          if (acc[composition.composition.id] == undefined) acc[composition.composition.id] = {
            name: composition.composition.name,
            count: 0,
            amount: 0
          };
          acc[composition.composition.id].count += composition.quantity;
          acc[composition.composition.id].amount += composition.quantity * (composition.composition.oPrice ?? composition.composition.price);
        })
        return acc;
      }, result);
    event.normalCompositionValues = result;
  }

  public static setLocationValues(event: EventEdto) {
    let result: { [key: string]: { name: string, count: number, amount: number } } = {};
    event.orders
      .filter(order => order.bookingType != BookingType.Employee)
      .reduce((acc, value) => {
        if (acc[value.location.id] == undefined) acc[value.location.id] = {
          name: value.location.name,
          count: 0,
          amount: 0
        };
        acc[value.location.id].count += 1;
        acc[value.location.id].amount += OrderHelper.getOrderTotal(value);
        return acc;
      }, result);
    event.locationValues = result;
  }
}
