import {OrderEdto} from "../../../models/order/order-edto";
import {CompositionOverrideLinkerEdto} from "../../../management/composition/composition-override-linker-edto";

export class OrderHelper {
  public static setBusinessCaseBookingTypeValues(order: OrderEdto) {
    let acc: { [key: string]: { amount: number } } = {};
    order.businessCaseBookingTypeValues = order.businessCaseInformations.reduce((accumulator, value) => {
      if (accumulator[value.bookingType] == undefined) accumulator[value.bookingType] = {amount: 0};
      accumulator[value.bookingType].amount += value.amount * value.signIndicator;
      return accumulator;
    }, acc)
  }

  public static getBusinessCaseBookingTypeTotal(order: OrderEdto): number {
    return Object.keys(order.businessCaseBookingTypeValues).reduce((accumulator, value) => {
      accumulator += order.businessCaseBookingTypeValues[parseInt(value)].amount
      return accumulator;
    }, 0)
  }

  public static getCompositionTotal(linker: CompositionOverrideLinkerEdto): number {
    return linker.quantity * (linker.compositionOverride?.price ?? linker.composition.price);
  }

  public static setCompositionPrices(order: OrderEdto) {
    let bcTotal = this.getBusinessCaseBookingTypeTotal(order);
    let oTotal = order.compositions.reduce((acc, value) => {
      acc += this.getCompositionTotal(value);
      return acc;
    }, 0);
    order.compositions.forEach(linker => {
      let share = oTotal == 0 ? 0 : this.getCompositionTotal(linker) / oTotal;
      let bcValue = share * bcTotal;
      linker.composition.oPrice = (linker.compositionOverride?.price ?? linker.composition.price) + bcValue / linker.quantity;
      if (Math.abs(linker.composition.oPrice - linker.composition.price) < Math.pow(10, -6)) linker.composition.oPrice = undefined;
    });
  }

  public static setOrderBookingValues(order: OrderEdto) {
    let result: { [key: number]: { count: number, amount: number } } = {};
    result[order.bookingType] = {
      count: 1, amount: order.compositions.reduce((acc, value) => {
        acc += this.getCompositionTotal(value);
        return acc;
      }, 0)
    }
    order.businessCaseInformations.forEach(bci => {
      if (result[bci.bookingType] == undefined) result[bci.bookingType] = {count: 0, amount: 0};
      result[bci.bookingType].amount += bci.signIndicator * bci.amount;
    })
    order.bookingValues = result;
  }

  public static getOrderTotal(order: OrderEdto): number {
    return Object.keys(order.bookingValues).reduce((accumulator, value) => {
      accumulator += order.bookingValues[parseInt(value)].amount
      return accumulator;
    }, 0)
  }
}
