import {Component, Inject, OnInit} from '@angular/core';
import {OrderDto} from "../models/order-dto";
import {OrderHttpService} from "../http-requests/order-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {PermissionService} from "../_auth/permission.service";
import {CompositionNl} from "../models/composition-nl";
import {LocationNl} from "../models/location-nl";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";
import {OrderHelper} from "../_modules/location/_services/order-helper";
import {OrderEdto} from "../models/order/order-edto";
import {CompositionDto} from "../models/composition-dto";
import {BookingType} from "../models/payment/booking-type";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public orders?: OrderEdto[] = undefined;
  public filtered_orders?: OrderEdto[];
  public inverse: boolean = false;
  public field: string = "";
  public date: string = "";
  public composition_filter?: string;
  public location_filter?: string;
  error?: HttpErrorResponse;

  constructor(private orderHttpService: OrderHttpService,
              @Inject('BASE_URL') private baseUrl: string,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              private globalAlertService: GlobalAlertService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let date = new Date();
    date.setMinutes(0, 0, 0);
    date.setTime(date.getTime() - date.getHours() * 60 * 60 * 1000);
    this.date = this.toIsoString(date).slice(0, 10);
  }

  OrderFilter() {
    this.FilterItem("", this.composition_filter, this.location_filter)
  }

  toIsoString(date: Date): string {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  CalculateTotal(order: OrderDto): number {
    let total = 0;
    order.compositions.forEach(composition => {
      if (composition.compositionOverride != undefined && composition.compositionOverride.price != null) total += composition.quantity * composition.compositionOverride.price;
      else total += composition.quantity * composition.composition.price;
    })
    return total;
  }

  AssignCopy() {
    this.filtered_orders = Object.assign([], this.orders);
  }

  FilterItem(value: string, composition: string | undefined = undefined, location: string | undefined = undefined) {
    this.composition_filter = composition;
    this.location_filter = location;
    if (this.composition_filter == undefined && this.location_filter == undefined) {
      this.AssignCopy();
    } else {
      this.filtered_orders = Object.assign([], this.orders?.filter(order => {
        return (order.compositions.findIndex(x => x.composition.id == this.composition_filter) > -1 || this.composition_filter == undefined) &&
          (order.location.id == this.location_filter || this.location_filter == undefined);
      }))
    }
  }

  SortBy(field: string) {
    if (this.filtered_orders == undefined) this.filtered_orders = []
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "date":
        this.filtered_orders.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
      case "price":
        this.filtered_orders.sort((a, b) => {
          if (a.total > b.total) return 1;
          if (a.total < b.total) return -1;
          return 0;
        })
    }
    if (this.inverse) this.filtered_orders.reverse();
  }

  order_selected?: OrderEdto;

  ShowDrinks(order: OrderEdto): void {
    this.order_selected = order;
  }

  SetActiveState(order: OrderEdto, state: boolean) {
    if (state) {
      this.globalAlertService.createAndShow("Warnung", "Aufgrund von Änderungen kann eine Bestellung nicht reaktiviert werden, bitte erneut buchen.", AlertLevel.warning);
      return;
    }

    let confirmation = confirm("Sind Sie sicher, dass Sie die Bestellung deaktivieren möchten? Diese Aktion kann nicht rückgängig gemacht werden.");
    if (!confirmation) return;

    this.orderHttpService.setActiveState(order.id, state, order.created)
      .withLoadingIndicator()
      .subscribe({
        next: x => {
          if (this.orders == undefined) this.orders = [];

          OrderHelper.setBusinessCaseBookingTypeValues(x);
          OrderHelper.setCompositionPrices(x);
          OrderHelper.setOrderBookingValues(x);
          x.total = OrderHelper.getOrderTotal(x);

          order.active = state;

          this.orders.splice(this.orders.indexOf(order), 0, x);
          this.FilterItem("", this.composition_filter, this.location_filter);
          this.globalAlertService.createAlertBannerModel("Erfolg", `Die Bestellung wurde erfolgreich ${state ? "aktiviert" : "deaktiviert"}.`, AlertLevel.success, 2000);
          this.globalAlertService.show();
        },
        error: error => {
          console.error(error);
          this.globalAlertService.createAlertBannerModel("Fehler", `Beim ${state ? "Aktivieren" : "Deaktivieren"} der Bestellung ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
          this.globalAlertService.show();
        }
      });
  }

  GetAllCompositions(): CompositionDto[] {
    let compositions: CompositionDto[] = [];
    this.orders?.forEach(order => {
      order.compositions.forEach(composition => {
        if (compositions.findIndex(x => x.id == composition.composition.id) == -1) compositions.push(composition.composition);
      })
    })
    compositions = compositions.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    })
    return compositions
  }

  GetAllLocations(): LocationNl[] {
    let locations: LocationNl[] = [];
    this.orders?.forEach(order => {
      if (locations.findIndex(x => x.id == order.location.id) == -1) locations.push(order.location);
    })
    locations = locations.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      return 0;
    })
    return locations;
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  LoadData() {
    this.orderHttpService.list(`${this.date}T00:00:00${this.toIsoString(new Date()).slice(19)}`).subscribe(result => {
      result.forEach(res => {
        OrderHelper.setBusinessCaseBookingTypeValues(res);
        OrderHelper.setCompositionPrices(res);
        OrderHelper.setOrderBookingValues(res);

        res.total = OrderHelper.getOrderTotal(res);
      })
      this.orders = result;
      this.AssignCopy();
      this.location_filter = undefined;
      this.composition_filter = undefined;
      this.OrderFilter();
      this.inverse = false;
      this.field = "date";
      this.SortBy("date");
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Laden der Bestellungen ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

    });
    this.LoadData();
  }

  protected readonly BookingType = BookingType;
}
