<app-navbar-container breadcrumb="Inventar">
  <app-navbar-item-large title="Kategorien" [link]="'/' + Routes.Categories"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_CategoryComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="M4 4h6v6H4zm10 0h6v6h-6zM4 14h6v6H4zm10 3a3 3 0 1 0 6 0a3 3 0 1 0-6 0"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Items" [link]="'/' + Routes.Items"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_ItemComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M12 2c-5.33 4.55-8 8.48-8 11.8c0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8m0 18c-3.35 0-6-2.57-6-6.2c0-2.34 1.95-5.44 6-9.14c4.05 3.7 6 6.79 6 9.14c0 3.63-2.65 6.2-6 6.2m-4.17-6c.37 0 .67.26.74.62c.41 2.22 2.28 2.98 3.64 2.87c.43-.02.79.32.79.75c0 .4-.32.73-.72.75c-2.13.13-4.62-1.09-5.19-4.12a.75.75 0 0 1 .74-.87"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Artikel-Kategorien" [link]="'/' + Routes.CompositionTypes"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_CompositionTypesComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path fill="currentColor"
            d="M184 72H40a16 16 0 0 0-16 16v112a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16m0 128H40V88h144zm48-144v120a8 8 0 0 1-16 0V56H64a8 8 0 0 1 0-16h152a16 16 0 0 1 16 16"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-small-container breadcrumb="Artikel-Kategorien"
                                   *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_CompositionComponent())">
    <app-navbar-item-small title="Ohne Zuordnung" [link]="'/' + Routes.Compositions"
                           [params]="{ other: false, type: '' }">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M8 12h8m-4 10c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"/>
      </svg>
    </app-navbar-item-small>
    <app-navbar-item-small *ngFor="let compositionType of CompositionTypeService.CompositionTypes"
                           [link]="'/' + Routes.Compositions" [params]="{ other: false, type: compositionType.id }"
                           [title]="compositionType.name">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
        <path fill="currentColor"
              d="M1024 640q79 0 149 30t122 82t83 123t30 149q0 80-30 149t-82 122t-123 83t-149 30q-80 0-149-30t-122-82t-83-122t-30-150q0-79 30-149t82-122t122-83t150-30"/>
      </svg>
    </app-navbar-item-small>
  </app-navbar-item-small-container>
  <app-navbar-item-large title="Tags" [link]="'/' + Routes.Tags"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_TagsComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor"
            d="M511.974 271.891a47.744 47.744 0 0 0-14.706-33.008L311.57 57.98a29.9 29.9 0 0 0-21.2-8.731h-33.142l217.754 212.6l.092.088a15.907 15.907 0 0 1 .741 22.337l-156.4 173.355l21.953 21.356L499.447 305.85a47.748 47.748 0 0 0 12.527-33.959"/>
      <path fill="currentColor"
            d="M426.9 283.161a23.924 23.924 0 0 0-5.231-24.742c-.106-.111-.2-.231-.306-.34L224.307 57.716l-.094-.094a31.791 31.791 0 0 0-22.628-9.373H60.194A44.244 44.244 0 0 0 16 92.443v141.1a32.121 32.121 0 0 0 10.045 23.28l210.32 200.2l.077.073a23.817 23.817 0 0 0 16.409 6.508q.447 0 .9-.017a24.111 24.111 0 0 0 6.741-1.217a23.854 23.854 0 0 0 10.047-6.517l151.444-164.621a24.033 24.033 0 0 0 4.917-8.071M252.5 428.2L48.077 233.612L48 233.54V92.443a12.208 12.208 0 0 1 12.194-12.194h141.39l191.7 194.918Z"/>
      <path fill="currentColor"
            d="M140 112a52 52 0 1 0 52 52a52.059 52.059 0 0 0-52-52m0 72a20 20 0 1 1 20-20a20.023 20.023 0 0 1-20 20"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Preisprofile" [link]="'/' + Routes.Configurations"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_ConfigurationsComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M3 19V5h18v14zm1-1h16V6H4zm0 0V6zm4.5-1.5h1v-1h1.23q.328 0 .549-.221q.221-.221.221-.548v-2.462q0-.327-.221-.548q-.221-.221-.548-.221H7.5v-2h4v-1h-2v-1h-1v1H7.27q-.328 0-.549.221q-.221.221-.221.548v2.462q0 .327.221.548q.221.221.548.221H10.5v2h-4v1h2zm7.5-.52l1.538-1.538h-3.076zm-1.538-6.172h3.076L16 8.269z"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Geschäftsvorfälle"
                         [link]="'/' + Routes.InventoryModule + '/' + InventoryRoutes.BusinessCases"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_Inventory_BusinessCasesComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path fill="currentColor"
            d="M28 128a8 8 0 0 1 0-16h28a8 8 0 0 0 0-16H40a24 24 0 0 1 0-48a8 8 0 0 1 16 0h8a8 8 0 0 1 0 16H40a8 8 0 0 0 0 16h16a24 24 0 0 1 0 48a8 8 0 0 1-16 0Zm204-72v136a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16v-40a8 8 0 0 1 16 0v40h120v-32H80a8 8 0 0 1 0-16h80v-32h-56a8 8 0 0 1 0-16h112V64H96a8 8 0 0 1 0-16h128a8 8 0 0 1 8 8m-56 88h40v-32h-40Zm40 48v-32h-40v32Z"/>
    </svg>
  </app-navbar-item-large>
</app-navbar-container>

