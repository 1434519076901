import {Component, OnInit} from '@angular/core';
import {EmployeeHttpService} from "../x-http-requests/employee-http.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {EmployeeDto} from "../x-models/employee-dto";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {CompositionMinimal} from "../../../models/composition/composition-minimal";
import {BookingType} from "../../../models/payment/booking-type";
import {EmployeeDetailsView} from "../x-models/x-enums/employee-details-view";
import {PermissionService} from "../../../_auth/permission.service";
import {EmploymentRelationshipLinkerDto} from "../x-models/employment-relationship-linker-dto";
import {CompanyRoutes} from "../x-models/x-enums/company-routes";
import {EmployeeDetailsSalesView} from "../x-models/x-enums/employee-details-sales-view";
import {EventDto} from "../../../models/event/event-dto";
import {OrderEdto} from "../../../models/order/order-edto";
import {CompositionOverrideLinkerEdto} from "../../../management/composition/composition-override-linker-edto";
import {GlobalDateService} from "../../../_services/global-date.service";
import {GlobalDateFormat} from "../../../enums/global-date-format";
import {EmployeeService} from "../_services/_dto-services/employee.service";
import {PlatformScannerService} from "../../../_services/platform-scanner.service";
import {DownloadInformation} from "../../x-components/x-models/download-information";
import {ServerEndpoints} from "../../../server.endpoints";
import {AlignmentService} from "../../../_services/alignment.service";
import {EmployeeStatsExtended} from "../../../models/company/employee-stats-extended";
import {EmployeeEventResponseSummary} from "../_models/employee/employee-event-response-summary";
import {EmploymentRelationshipNl} from "../x-models/employment-relationship-nl";
import {EmployeeStatsHelper} from "../x-models/employee-stats-dto";
import {OrderHelper} from "../../location/_services/order-helper";

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  constructor(private employeeHttpService: EmployeeHttpService,
              private employeeService: EmployeeService,
              private globalAlertService: GlobalAlertService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              public GlobalDateService: GlobalDateService,
              public PlatformScannerService: PlatformScannerService,
              public AlignmentService: AlignmentService) {
    if (PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeDetailsView())) {
      this.view = EmployeeDetailsView.Data;
    }
  }

  employee?: EmployeeDto;
  start: string = "";
  end: string = "";
  now = new Date();

  isFinished(er: EmploymentRelationshipLinkerDto): boolean {
    return er.end < new Date();
  }

  employeeSales?: CompositionMinimal[]
  employeeShifts?: EmployeeEventResponseSummary[]
  employeeSalesError: boolean = false;
  view: EmployeeDetailsView = EmployeeDetailsView.Shifts;

  GetEmployeeSalesSum(): number {
    if (!this.employeeSales) return 0;

    return this.employeeSales.map(x => x.quantity * x.price).reduce((sum, current) => sum + current, 0);
  }

  LoadEmployee(id: string) {
    this.employee = this.employeeService.GetById(id);
    if (!this.employee) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiter konnte nicht geladen werden.", AlertLevel.error, 3000);
      this.globalAlertService.show();
      this.router.navigate([Routes.CompanyEmployee]);
      return;
    }

    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Employee_EmployeeSalesList())) {
      this.LoadEmployeeSales(id, this.start, this.end);
    }
    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Employee_GetEmployeeShiftList())) {
      this.LoadEmployeeShiftsStr(id, this.start, this.end)
    }

    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Employee_GetEmploymentRelationships())) {
      this.loadEmploymentRelationships(id);
    }

    if (this.employeeAttributeEmployee != undefined) {
      this.employeeAttributeEmployee = this.employee;
    }

    if (this.PermissionService.CheckPermission(this.PermissionService.CombinedPermission_EmployeeDetailsView())) {
      this.loadStats(id);
    }
  }

  employeeDetailsSalesView: EmployeeDetailsSalesView = EmployeeDetailsSalesView.List;
  employeeSalesEvents: EventDto[] | undefined | null = undefined;

  protected employmentRelationships: EmploymentRelationshipLinkerDto[] | undefined;

  private loadEmploymentRelationships(id: string) {
    this.employeeHttpService.getEmploymentRelationships(id).subscribe({
      next: relationships => {
        this.employmentRelationships = relationships;
      },
      error: err => {
        this.employmentRelationships = undefined;
        console.error(err);
        this.globalAlertService.createAndShow("Fehler", "Arbeitsverhältnisse konnten nicht geladen werden.", AlertLevel.error);
      }
    })
  }

  calculateOrderTotal(order: OrderEdto): number {
    return order.compositions.reduce((sum: number, linker: CompositionOverrideLinkerEdto) => sum + linker.quantity * (linker.compositionOverride?.price ?? linker.composition.price), 0);
  }

  calculateEventTotal(event: EventDto): number {
    return event.orders.reduce((sum: number, order: OrderEdto) => sum += this.calculateOrderTotal(order), 0);
  }

  LoadEmployeeSales(id: string, start: string, end: string) {
    this.employeeHttpService.employeeSalesOrders(id, start, end).subscribe({
      next: events => {
        this.employeeSalesEvents = events;
      },
      error: error => {
        console.error(error);
        this.employeeSalesEvents = null;
        this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiterverkäufe nach Events konnten nicht geladen werden.", AlertLevel.error, 2000);
        this.globalAlertService.show();
      }
    })

    this.employeeHttpService.employeeSalesList(id, start, end).subscribe(x => {
      this.employeeSalesError = false;
      this.employeeSales = x;
    }, error => {
      this.employeeSales = undefined;
      this.employeeSalesError = true;
      //this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiterverkäufe konnten nicht geladen werden.", AlertLevel.error, 3000);
      //this.globalAlertService.show()
    })
  }

  LoadEmployeeShiftsStr(id: string, start: string, end: string) {
    this.LoadEmployeeShifts(id, new Date(start), new Date(end));
  }

  employmentRelationship: EmploymentRelationshipNl | undefined;

  LoadEmployeeShifts(id: string, start: Date, end: Date) {
    this.employeeHttpService.getEmployeeShiftList(id, start, end).subscribe({
      next: summary => {
        this.employmentRelationship = summary.employmentRelationship;
        this.employeeShifts = summary.employeeEventResponses;
      },
      error: err => {
        console.error(err)
        this.globalAlertService.createAndShow("Fehler", "Arbeitszeiten konnten nicht geladen werden.", AlertLevel.error);
      }
    })
  }

  stats: EmployeeStatsExtended | undefined;

  loadStats(employeeId: string) {
    this.employeeHttpService.getStats(employeeId).subscribe({
      next: x => {
        this.stats = x;
      },
      error: error => {
        console.error(error);
        this.globalAlertService.createAndShow("Fehler", "Informationen konnten nicht geladen werden.", AlertLevel.error);
      }
    })
  }

  getAverageHoursPerMonth(firstShift: Date | undefined, hoursWorked: number | undefined): number {
    if (!firstShift || !hoursWorked) return 0;
    let now: Date = new Date();

    // +1 because we want to include the current month
    let months = now.getFullYear() * 12 + now.getMonth() - firstShift.getFullYear() * 12 - firstShift.getMonth() + 1;

    return hoursWorked / months;
  }


  // employee attribute popup
  mouseEvent?: MouseEvent;
  employeeAttributePopupVisible: boolean = false;
  employeeAttributeEmployee: EmployeeDto | undefined;

  openPopup(e: any) {
    this.employeeAttributeEmployee = this.employee;
    this.mouseEvent = e;
    this.employeeAttributePopupVisible = true;
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["start"]) this.start = params["start"];
      if (params["end"]) this.end = params["end"];
    })

    this.route.queryParams.subscribe(params => {
      if (!params.id) {
        this.globalAlertService.createAlertBannerModel("Fehler", "Fehlerhafte Parameter beim Seitenaufruf, Weiterleitung zur Übersicht.", AlertLevel.error, 3000);
        this.globalAlertService.show();
        this.router.navigate([Routes.CompanyEmployee])
      } else {
        this.start = params.start ?? (new Date()).toISOString().split("T")[0];
        this.end = params.end ?? (new Date()).toISOString().split("T")[0];

        this.employeeService.EntitiesChanged.subscribe(x => {
          if (!x || x.length == 0) return;
          this.LoadEmployee(params.id)
        })
      }
    })
  }

  getDrinkCount(sales: CompositionMinimal[]): number {
    return sales.reduce((sum, current) => sum + current.quantity, 0);
  }

  getTotalSalary(): number {
    if (!this.employeeShifts) return 0;
    return this.employeeShifts
      .filter(x => x.stats)
      .reduce((sum, current) => sum + EmployeeStatsHelper.getTotal(current.stats!), 0);
  }

  getTotalHours(): number {
    if (!this.employeeShifts) return 0;
    return this.employeeShifts
      .filter(x => x.stats)
      .reduce((sum, current) => sum + (current.stats?.hoursWorked ?? 0), 0);
  }

  getIsoString(date: string): string {
    return new Date(date).toISOString()
  }

  // employment relationship
  erVisible: boolean = false;

  erReloadEmployee() {
    if (this.erVisible) return;

    this.route.queryParams.subscribe(params => {
      if (!params.id) {
        this.globalAlertService.createAlertBannerModel("Fehler", "Fehlerhafte Parameter beim Seitenaufruf, Weiterleitung zur Übersicht.", AlertLevel.error, 3000);
        this.globalAlertService.show();
        this.router.navigate([Routes.CompanyEmployee])
      } else {
        this.start = params.start ?? (new Date()).toISOString().split("T")[0];
        this.end = params.end ?? (new Date()).toISOString().split("T")[0];

        this.LoadEmployee(params.id)
      }
    })
  }

  // download
  downloadInformation: DownloadInformation = new DownloadInformation();
  downloadParams: string = "";

  getDownloadParams(): string {
    if (!this.employee) return "";
    let start = new Date(this.start)
    start.setHours(0);
    let end = new Date(this.end)
    end.setHours(0);
    end.setDate(end.getDate() + 1);
    return `id=${this.employee?.id}&start=${start.toISOString()}&end=${end.toISOString()}`;
  }


  protected readonly N5BookingType = BookingType;
  protected readonly Routes = Routes;
  protected readonly EmployeeDetailsView = EmployeeDetailsView;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly EmployeeDetailsSalesView = EmployeeDetailsSalesView;
  protected readonly GlobalDateFormat = GlobalDateFormat;
  protected readonly ServerEndpoints = ServerEndpoints;
  protected readonly EmployeeStatsHelper = EmployeeStatsHelper;

  redirectToSettings() {
    if (!this.employee) return;
    this.router.navigate([Routes.CompanyEmployeeSettings], {queryParams: {id: this.employee.id, dv: true}}).then();
  }

  protected readonly OrderHelper = OrderHelper;
}
