<div class="px-4 sm:px-6 lg:px-8 h-full flex flex-col" #employee>
  <div class="sm:flex sm:items-center">
    <div class="flex space-x-1">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl pointer-events-none"
          (click)="LoadAllEmployees()"
          [ngClass]="{'!text-theme-primary-700 cursor-pointer select-none hover:!text-theme-primary-900 pointer-events-auto': relationship || relationshipId != ''}">
        Mitarbeiter{{ relationship || relationshipId != '' ? ":" : "" }}
      </h1>
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl" *ngIf="relationship">{{ relationship.name }}</h1>
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl" *ngIf="!relationship && relationshipId != ''">
        Unbekannt</h1>
    </div>
  </div>
  <layout-form-container *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_Add())"
                         submitText="Mitarbeiter anlegen" clearText="Felder leeren"
                         [(searchValue)]="filter_text" (searchValueChange)="Filter(filter_text)"
                         (isCollapsedChange)="CollapseChange($event)" [preventClearOnCollapseChange]="true"
                         (submit)="Add()" (clear)="ResetFilter()"
                         [isCollapsed]="false">
    <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <div class="sm:col-span-3">
        <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Vorname</label>
        <input [(ngModel)]="employee_firstname" (ngModelChange)="FilterTriggered()"
               type="text" name="first-name" id="first-name" autocomplete="given-name"
               class="mt-2 text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>

      <div class="sm:col-span-3">
        <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nachname</label>
        <input [(ngModel)]="employee_lastname" (ngModelChange)="FilterTriggered()"
               type="text" name="last-name" id="last-name" autocomplete="family-name"
               class="mt-2 text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>

      <div class="sm:col-span-5">
        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email Adresse</label>
        <input [(ngModel)]="employee_email" (ngModelChange)="FilterTriggered()"
               id="email" name="email" type="email" autocomplete="email"
               class="mt-2 text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>

      <div class="sm:col-span-1 flex flex-col">
        <label for="email-reset" class="block text-sm font-medium leading-6 text-gray-900">Passwort
          vergeben</label>
        <div class="mt-2 inline-flex w-full gap-x-1.5 h-full">
          <div class="flex">
            <input [(ngModel)]="employee_email_reset" id="email-reset" name="email-reset" type="checkbox"
                   autocomplete="email-reset"
                   class="place-self-center text-center block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
          </div>
          <label for="email-reset" class="place-self-center">Email senden</label>
        </div>
      </div>
    </div>
  </layout-form-container>

  <!-- <form *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_Add())"
         class="grid flex py-4 gap-x-4 items-end -mx-4" name="new-employee">
     <div class="grid grid-cols-1 gap-x-8 gap-y-8 w-full">
       <form class="bg-gray-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
         <div class="px-3 py-2 sm:py-6">

         </div>
         <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
           <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 rounded-md"
                   (click)="ResetFilter()">
             Felder
             leeren
           </button>
           <button type="submit" (click)="Add()"
                   class="rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-900">
             Mitarbeiter anlegen
           </button>
         </div>
       </form>
     </div>-->

  <!--<div class="grow">
    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Vorname</label>
    <div class="mt-2">
      <input [(ngModel)]="employee_firstname" (ngModelChange)="FilterTriggered()" type="text" name="first-name"
             id="first-name" autocomplete="given-name"
             class="block h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
    </div>
  </div>
  <div class="grow">
    <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nachname</label>
    <div class="mt-2">
      <input [(ngModel)]="employee_lastname" (ngModelChange)="FilterTriggered()" type="text" name="last-name"
             id="last-name" autocomplete="family-name"
             class="block h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
    </div>
  </div>
  <div class="col-span-2 shrink">
    <label for="submit" class="block text-sm font-medium leading-6 text-gray-900 sr-only"></label>
    <div class="mt-2">
      <input (click)="Add()" type="submit" name="submit" id="submit" autocomplete="family-name"
             class="px-4 block h-10 w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
    </div>
  </div>
  </form>-->
  <div class="grid grid-cols-2">
    <div
      class="flex flex-col md:!flex-row md:!inline-flex text-sm place-self-center justify-self-start divide-x-2 -mx-2">
      <div class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none text-sm fw-bold px-2"
           *ngIf="employee_lastname != '' || employee_firstname != '' || filter_text != ''">
        <span (click)="ResetFilter()">Felder leeren</span>
      </div>
      <div class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none text-sm fw-bold px-2">
        <span *ngIf="!selectionMode" (click)="selectionMode = true; selectAll(false)">Bearbeiten</span>
        <span *ngIf="selectionMode" (click)="selectionMode = false; selectAll(false)">Fertig</span>
      </div>
      <div class="inline-flex fw-light px-2" *ngIf="selectionMode">
        <div
          *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeEditEmployeeRelationship())">
          <span class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none"
                [ngClass]="{'pointer-events-none hover:!text-gray-600 !text-gray-600': !isAnySelected()}"
                (click)="employmentRelationshipSelectorVisible = true">Arbeitsverhähltnis festlegen</span>
        </div>
      </div>
    </div>
    <div class="place-self-center justify-self-end flex"
         *ngIf="PermissionService.CheckPermission(PermissionService.Document_Employee_Download())">
      <span
        class="py-0.5 px-2 border-gray-300 border-2 mr-3 text-gray-300 rounded-xl place-self-center cursor-pointer select-none"
        (click)="setDownloadExtended(!downloadExtended)" [ngClass]="{
            '!text-red-600 !border-red-600': downloadExtended
            }">
        Erweitert
      </span>
      <app-download class="place-self-center" [Params]="'extended=' + downloadExtended"
                    [DownloadInformation]="downloadInformation" [Route]="ServerEndpoints.Document_Employee_Download"/>
    </div>
  </div>
  <div class="-mx-4 mt-8 sm:-mx-0 overflow-x-auto relative h-full overflow-y-auto">
    <table class="min-w-full divide-y divide-gray-300" id="eaRelative" #ea_relative>
      <thead>
      <tr>
        <th *ngIf="selectionMode">
          <input type="checkbox" [ngModel]="isAllSelected()" (ngModelChange)="selectAll($event)">
        </th>
        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <app-sorting-indicator displayName="Name" [currentSorting]="SortedBy" [inverseState]="Inverse"
                                 [thisSorting]="Sorting.Name" (click)="SetSorting(Sorting.Name)"/>
        </th>
        <th></th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
          <app-sorting-indicator displayName="Letzter Dienst" [currentSorting]="SortedBy" [inverseState]="Inverse"
                                 [thisSorting]="Sorting.Date" (click)="SetSorting(Sorting.Date)"/>
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell">
          Arbeitsverhältnis
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell">Telegram / Email
        </th>
        <th
          scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Login erlaubt?
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let employee of filtered_employees" class="relative">
        <td *ngIf="selectionMode">
          <input type="checkbox" [(ngModel)]="employee.selected">
        </td>
        <!-- todo check canactivate -->
        <td
          class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-0 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
          [routerLink]="['/' + Routes.CompanyEmployeeDetails]" [queryParams]="{ id: employee.id }"
          [queryParamsHandling]="'merge'">{{ employee.firstName }} {{ employee.lastName }}
        </td>
        <td>
          <div class="flex space-x-2">
            <svg (click)="openPopup($event, employee)" id="eaToggle" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="currentColor"
                 class="bi bi-info-circle-fill text-gray-500 cursor-pointer hover:text-gray-700 place-self-center"
                 viewBox="0 0 16 16">
              <path
                d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
            </svg>
            <div class="hidden md:flex place-self-center text-gray-500">
              <div *ngFor="let attr of employee.employeeAttributes" class="place-self-center">
                <span *ngIf="attr.shortName != ''"
                      class="border-x-2 -mx-px px-1 border-gray-500">{{ attr.shortName }}</span>
              </div>
            </div>
          </div>
        </td>
        <td
          class="hidden whitespace-nowrap px-3 py-2 text-sm text-gray-500 lg:table-cell">{{ (employee.lastShift) | date : "dd.MM.YYYY, HH:mm" }}{{ employee.lastShift ? " Uhr" : "-" }}
        </td>
        <td class="hidden whitespace-nowrap px-3 py-2 text-sm text-gray-500 xl:table-cell"
            [ngClass]="{'cursor-pointer select-none hover:text-gray-700': employee.lastEmploymentRelationship}"
            (click)="NavigateToEmploymentRelationship(employee.lastEmploymentRelationship?.id)">{{ employee.lastEmploymentRelationship?.name }}
        </td>
        <td class="px-3 py-2 text-sm text-gray-500">
          <div class="whitespace-nowrap">
            <span
              *ngIf="employee.telegram && ((employee.telegram.userId && employee.telegram.userId != '') || (!employee.telegram!.userId && employee.telegram!.username))">
            {{ employee.telegram!.username ? '@' : 'UID:' }}
          </span>
            <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
               [ngClass]="{'!text-gray-500 pointer-events-none': !IsMobile() && !employee.telegram!.username}"
               *ngIf="employee.telegram && ((employee.telegram.userId && employee.telegram.userId != '') || (!employee.telegram!.userId && employee.telegram!.username))"
               href="{{ employee.telegram!.username ? 'https://t.me/'+employee.telegram!.username : 'tg://user?id='+employee.telegram!.userId }}"
               target="_blank">
              {{ employee.telegram!.username && employee.telegram!.username != '' ? employee.telegram!.username : employee.telegram!.userId }}
            </a>
            <span *ngIf="employee.telegram && employee.telegram.manuallyUpdated" class="font-bold text-yellow-700">
            (M)
          </span>
            <!--<span
              *ngIf="employee.telegram && employee.telegram.chatId && PermissionService.CheckPermission(PermissionService.Company_Employee_RequestVerification())">
              <span>-&nbsp;</span>
              <span class="text-yellow-700 hover:text-yellow-500 cursor-pointer"
                    (click)="Verify(employee.id, SocialTypes.Telegram)">Verifizieren</span>
            </span>-->
          </div>
          <div class="whitespace-nowrap" *ngIf="employee.emailAddress && !employee.emailAddress.isNullOrWhitespace()">
            <a href="mailto:{{employee.emailAddress}}">{{ employee.emailAddress }}</a>
          </div>
        </td>
        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          <input type="checkbox" [(ngModel)]="employee.canLogin"
                 (ngModelChange)="setCanLogin(employee)"
                 [class.opacity-50]="!PermissionService.CheckPermission(PermissionService.Company_Employee_AllowEmployeeLogin())"
                 [disabled]="!PermissionService.CheckPermission(PermissionService.Company_Employee_AllowEmployeeLogin())">
        </td>
        <td class="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 inline-flex w-full">
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_Get())"
             (click)="redirectToSettings(employee.id)"
             class="cursor-pointer text-gray-600 hover:text-gray-800 place-self-center pr-2 ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
                 viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
            </svg>
          </a>
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_Delete())"
             (click)="Delete(employee)" class="cursor-pointer text-red-600 hover:text-red-900 place-self-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>
        </td>
      </tr>

      <!-- More people... -->
      </tbody>
    </table>

    <app-employee-attribute-popup [redirectPath]="[ '/' + Routes.CompanyEmployee ]" [useRelativeX]="true"
                                  [(employee)]="popupEmployee" [(visible)]="visible"
                                  [relative]="ea_relative"
                                  [mouseEvent]="mouseEvent"></app-employee-attribute-popup>
  </div>
  <!--<app-password-entry [OldPasswordEntry]="false"></app-password-entry>-->
</div>

<app-employment-relationship-popup [(visible)]="employmentRelationshipSelectorVisible"
                                   [changeIds]="EmployeeService.GetSelectedIds()"></app-employment-relationship-popup>
