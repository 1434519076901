import {Component, Host, HostBinding, Input, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'layout-page-scrollable',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './page-scrollable.component.html',
  styleUrl: './page-scrollable.component.scss'
})
export class PageScrollableComponent {
}
