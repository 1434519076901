import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {OrderDto} from "../models/order-dto";
import {ServerEndpoints} from "../server.endpoints";
import {OrderEdto} from "../models/order/order-edto";

@Injectable({
  providedIn: 'root'
})
export class OrderHttpService {
  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  list(date: string): Observable<OrderEdto[]> {
    return this.http.get<OrderEdto[]>(this._baseUrl + ServerEndpoints.Booking_Order_List + "?all=true&date=" + date);
  }

  setActiveState(id: string, state: boolean, date: Date | undefined = undefined): Observable<OrderEdto> {
    return this.http.put<OrderEdto>(this._baseUrl + ServerEndpoints.Booking_Order_SetActiveState + "?id=" + id + "&state=" + state +
      "&time=" + date?.toISOString(), {});
  }

  getOrderCountFromShard(shardId: string, days: number): Observable<{ count: number, start: Date, end: Date }[]> {
    return this.http.get<{
      count: number,
      start: Date,
      end: Date
    }[]>(this._baseUrl + ServerEndpoints.Booking_Order_GetOrderCountFromShard + "?shardId=" + shardId + "&days=" + days);
  }
}
