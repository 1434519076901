<layout-page-container>
  <layout-header-container title="Geräte">
    <span (click)="Connection?.start()"
          class="place-self-center pl-2 text-yellow-400 pointer-events-none select-none !text-3xl"
          [ngClass]="{
                  '!text-green-600': Connection?.state == HubConnectionState.Connected,
                  '!text-red-600 pointer-events-auto cursor-pointer': Connection?.state == HubConnectionState.Disconnected
                  }">
      {{ Connection?.state }}
    </span>
  </layout-header-container>
  <div class="flex content-around justify-around pb-3">
    <div class="inline-flex space-x-2">
      <app-switch-medium [(isOn)]="toggleActive" (isOnChange)="Filter()"></app-switch-medium>
      <span class="place-self-center">aktiv</span>
    </div>
    <div class="inline-flex space-x-2">
      <app-switch-medium [(isOn)]="toggleLocked" (isOnChange)="Filter()"></app-switch-medium>
      <span class="place-self-center">gesperrt</span>
    </div>
  </div>
  <layout-page-scrollable>
    <div class="py-8">
      <p *ngIf="Devices.length == 0">
        <span *ngIf="!initial;else loading">Keine Geräte gefunden.</span>
        <ng-template #loading>
          <span class="animate-pulse">Lade Geräte...</span>
        </ng-template>
      </p>
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3" *ngIf="Devices.length > 0">
        <li *ngFor="let device of DevicesFiltered; let index = index; trackBy: TrackByIndex;"
            [class.!bg-white]="GetSignalRClient(device.id)"
            class="bg-gray-300 col-span-1 rounded-lg shadow grid relative">
          <div class="flex w-full items-center justify-between space-x-6 p-6 pb-3 pt-3 relative overflow-hidden">
            <div class="mt-1 truncate text-sm text-gray-500 grid grid-cols-2 w-full">
              <div class="inline-flex col-span-2 pb-3">
                <h3
                  class="text-lg place-self-center grow font-medium text-gray-900 text-ellipsis overflow-hidden whitespace-nowrap">
                  {{ device.name }}
                </h3>
                <div class="p-2 shrink place-self-center flex ms-auto">
                  <svg
                    *ngIf="device.deviceInformation && (device.deviceInformation.batteryState == BatteryState.Charging || device.deviceInformation.batteryState == BatteryState.Full)"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="mr-1 bi bi-lightning-charge-fill text-gray-700 place-self-center" viewBox="0 0 16 16">
                    <path
                      d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                  </svg>
                  <span *ngIf="device.deviceInformation"
                        class="place-self-center pr-3">{{ (device.deviceInformation.batteryLevel * 100).toFixed(0) }}
                    %</span>
                  <div *ngIf="device.deviceInformation" style="width: 2px; height: 10px"
                       class="bg-gray-500 place-self-center rounded-l-md">

                  </div>
                  <div *ngIf="device.deviceInformation" style="width: 40px; height: 20px; padding: 3px"
                       class="bg-gray-500 h-full rounded-md">
                    <div class="w-full h-full bg-white">
                      <div class="bg-green-500 h-full ms-auto"
                           [class.!bg-yellow-500]="device.deviceInformation.batteryLevel<=.35 && device.deviceInformation.batteryLevel > .2"
                           [class.!bg-red-500]="device.deviceInformation.batteryLevel<=.2"
                           [style.width.%]="device.deviceInformation.batteryLevel*100">
                        <div *ngIf="device.deviceInformation.batteryState == BatteryState.Charging"
                             class="bg-white h-full"
                             [style.animation-duration]="(device.deviceInformation.batteryLevel > .5 ? 5*device.deviceInformation.batteryLevel : 3) + 's'"
                             [class.battery-charging]="device.deviceInformation.batteryState == BatteryState.Charging">

                        </div>
                      </div>
                    </div>
                  </div>
                  <a *ngIf="PermissionService.CheckPermission(PermissionService.Device_Information_Get())"
                     class="select-none text-gray-600 hover:text-gray-900 cursor-pointer place-self-center pl-3"
                     (click)="redirectToSettings(device.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
                         viewBox="0 0 16 16">
                      <path
                        d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                    </svg>
                  </a>
                </div>
              </div>

              <span class="">Geräte ID:</span>
              <span class="truncate text-ellipsis">{{ device.id }}</span>
              <span class="">Verkaufspunkt:</span>
              <span class="truncate text-ellipsis">{{ !device.location ? "---" : device.location.name }}</span>
              <div *ngIf="GetSignalRClient(device.id)" class="grid grid-cols-2 col-span-2">
                <span class="">Verbindungs ID:</span>
                <span class="">{{ GetSignalRClient(device.id)?.id }}</span>
              </div>
              <span class="mt-3">Hersteller:</span>
              <span class="mt-3">{{ device.deviceInformation?.manufacturer ?? "N/A" }}</span>
              <span class="">Modell:</span>
              <span class="">{{ device.deviceInformation?.model ?? "N/A" }}</span>
              <span class="">Betriebssystem:</span>
              <span
                class="">{{ device.deviceInformation?.platform ?? "N/A" }} {{ device.deviceInformation?.osVersion ?? "" }}</span>
              <div class="grid grid-cols-2 col-span-2" *ngIf="signalRGroup">
            <span class="pt-3"
                  [class.text-red-600]="(signalRGroup.appBuild && signalRGroup.appBuild != device.deviceInformation.appBuild) || (signalRGroup.appVersion && signalRGroup.appVersion != device.deviceInformation.appVersion)"
                  *ngIf="device.deviceInformation">App Version:</span>
                <span class="pt-3 inline-flex" *ngIf="device.deviceInformation">
            <svg
              *ngIf="(signalRGroup.appBuild && signalRGroup.appBuild != device.deviceInformation.appBuild) || (signalRGroup.appVersion && signalRGroup.appVersion != device.deviceInformation.appVersion)"
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-exclamation-triangle place-self-center mr-1 text-red-600" viewBox="0 0 16 16">
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>
            <span class="pr-1"
                  [class.text-red-600]="signalRGroup.appVersion && signalRGroup.appVersion != device.deviceInformation.appVersion">{{ device.deviceInformation.appVersion }}</span>
            (<span
                  [class.text-red-600]="signalRGroup.appBuild && signalRGroup.appBuild != device.deviceInformation.appBuild">{{ device.deviceInformation.appBuild }}</span>)
          </span>
              </div>
              <span class="">Backlog:</span>
              <div class="inline-flex">
            <span [class.text-red-600]="(device.deviceStats?.backlogCount ?? 0) > 0"
                  class="">{{ device.deviceStats?.backlogCount ?? "N/A" }}</span>
                <span
                  *ngIf="GetSignalRClient(device.id) && (device.deviceStats?.backlogCount ?? 0) > 0 && PermissionService.CheckPermission(PermissionService.Device_Information_RequestInformation())"
                  class="ml-2 cursor-pointer select-none">(<span class="fw-bold text-red-600 hover:text-red-800"
                                                                 (click)="RequestInformation(GetSignalRClient(device.id)!.id, RequestedInformation.SerializedBacklog)">anzeigen</span> | <span
                  class="fw-bold text-red-600 hover:text-red-800"
                  (click)="RequestInformation(GetSignalRClient(device.id)!.id, RequestedInformation.BacklogPushError)">senden</span>)</span>
              </div>
              <span class="">Verbleibende IDs:</span>
              <span [class.text-red-600]="(device.deviceStats?.remainingOrderStatus ?? 20000) < 1000"
                    class="">{{ device.deviceStats?.remainingOrderStatus ?? "N/A" }}</span>
              <span *ngIf="device.deviceInformation"
                    class="col-span-2 text-center pt-4 text-gray-400">Aktualisiert um {{ (device.deviceInformation.modified) | date : "dd.MM.YYYY, HH:mm" }}
                Uhr</span>
            </div>
          </div>
        </li>
        <!-- More people... -->
      </ul>
    </div>
  </layout-page-scrollable>
</layout-page-container>
<app-json-viewer-popup [json]="sBacklog"></app-json-viewer-popup>
